Apt.fn.make('home', {
	/**
	 * Initialize module
	 */
	init: function() {
		var scope = this,
			$form = $$('homeSearch'),
			size = $.screen.size();

		LS.common.setBasic(true);

		scope.locator = Apt.fn.locator()
			.init('$homeLocator', {
				default: true,
				address: true,
				fetch: true,
				focused: function() {
					if (size < 3) {
						LS.util.crop();
					}

					$form[0].classList.add('-is-active');
				},
				collapse: function() {
					$form[0].classList.remove('-is-active');

					LS.util.uncrop();
				},
				changed: function() {
					$form.trigger('submit');
				}
			});

		$form.on('submit', function() {
			LS.header.search(scope.locator);
		}, {
			cancel: true,
			namespace: 'home'
		});

		scope.drag = [];

		LS.util.load('drag', function() {
			$$('overflowed').each(function(el) {
				var drag = Apt.fn.drag()
					.init(el);

				if (drag) {
					scope.drag.push(drag);
				}
			});
		});

		LS.util.idle(function() {
			scope.$private.initRelevant();

			scope.features = Apt.fn.meta()
				.init('$homeFeatures');

			scope.territories = Apt.fn.meta()
				.init();

			if (size > 3) {
				var path = '/assets/modules/maps@' + $.get('versions.maps') + '/';

				LS.util.prefetch([
					path + 'style.min.css',
					path + 'script.min.js',
					path + 'data/dynamic.json',
					'/assets/fonts/averta-semi/0-255.pbf'
				]);
			}
		});
	},


	/**
	 * Unload module
	 */
	unload: function() {
		var scope = this;

		LS.util.reset('home', scope, [
			'features',
			'locator',
			'relevant',
			'territories'
		]);

		scope.drag.forEach(function(drag) {
			drag.$destroy();
		});

		LS.common.setBasic();
	}
}, {
	/**
	 * Initialize relevant properties
	 */
	initRelevant: function() {
		var pub = this.$public,
			$relevant = $$('homeRelevant');

		LS.util.scrolled($relevant, 'home', function() {
			pub.relevant = Apt.fn['properties.relevant']()
				.init({
					context: $relevant,
					scroll: true,
					params: {
						near: LS.user.center(),
						limit: 6,
						featured: true,
						random: true,
						format: 'sale',
						size: {
							min: 5
						}
					}
				}, null);
			}, {
				margin: 420
			});
	}
});